<template>
  <nav v-if="data.length" class="breadcrumb" :class="classNav" aria-label="breadcrumbs">
    <ul>
      <template v-for="{ name, label, params, icon, isActive } in data" :key="name">
        <li :class="isActive ? 'is-active' : ''">
          <router-link :to="{ name: name, params }">
            <o-icon v-if="icon" :icon="icon" size="small" />
            <span>{{ label }}</span>
          </router-link>
        </li>
      </template>
    </ul>
  </nav>
</template>
<script>
import { computed } from 'vue';
export default {
  props: {
    data: { type: [Object, Array], default: () => [] },
    alignment: { type: String, enum: ['centered', 'right'], default: null },
    separators: { type: String, enum: ['arrow', 'bullet', 'dot', 'succeeds'], default: 'succeeds' },
    size: { type: String, enum: ['small', 'medium', 'large'], default: 'small' },
  },
  setup(props) {
    const classNav = computed(() => {
      const { alignment, separators, size } = props;
      const classes = [];
      if (size) classes.push(`is-${size}`);
      if (alignment) classes.push(`is-${alignment}`);
      if (separators) classes.push(`has-${separators}-separator`);
      return classes.join(' ');
    });
    return { classNav };
  },
};
</script>
<style lang="sass" scoped>
.breadcrumb
  margin-bottom: 16px !important
  @media screen and (max-width: $bp-sm)
    padding-top: 16px !important
  a
    color: $link-main
  .icon
    color: $grey
  .is-small
      font-size: 15px
.has-succeeds-separator
  li
    a
      padding-right: 0
    + li::before
      font: normal normal normal 20px/1 "Material Design Icons"
      line-height: 10px
      width: 14px
      content: "\F0142" !important
</style>
