<template>
  <ModalStagesMonthlyRun
    v-if="isModalAddStageOpen"
    v-model:is-open="isModalAddStageOpen"
    v-model:is-edit="isEdit"
    v-model:is-stage="isStage"
    :data="rowData"
    @success:save="reload"
  />
  <Table
    id="table-stages"
    ref="tableStages"
    v-model:loading="isLoading"
    :show-detail-icon="false"
    detail-key="id"
    detailed
    :api-url="apiUrl"
    header="ETAPAS"
    paginated
    :focusable="false"
    :params="params"
  >
    <template #header>
      <Field
        v-model="fieldSearch.value"
        class="search"
        placeholder="Nombre etapa"
        icon-right="magnify"
      >
        <template #addonsLeft>
          <Dropdown
            v-model:selected="itemSelected"
            grouped
            label="Campos de búsqueda"
            variant="info"
            :data="dropdownItems"
          />
        </template>
      </Field>
    </template>
    <template #extra>
      <Button
        label="Nueva etapa"
        icon-left="plus-circle"
        variant="primary"
        @click="modalNewStage"
      />
    </template>

    <o-table-column
      v-slot="props"
      sortable
      sort-icon-size="large"
      label="Nombre etapa"
      field="name"
    >
      <div class="has-text-centered">
        {{ props.row.name }}
      </div>
    </o-table-column>

    <o-table-column
      v-slot="props"
      sortable
      sort-icon-size="large"
      label="Tipo de la etapa"
      field="stage_type_name"
    >
      <div class="has-text-centered">
        {{ props.row.stage_type_name }}
      </div>
    </o-table-column>

    <o-table-column
      v-slot="props"
      sortable
      sort-icon-size="large"
      label="Ejecución"
      field="is_omissible"
    >
      <div class="has-text-centered">
        {{ getOmissibleNameByID(props.row.is_omissible) }}
      </div>
    </o-table-column>
    <o-table-column v-slot="props" label="Acciones" field="actions" sort-icon-size="large">
      <div class="user-actions">
        <o-tooltip variant="black" label="Editar" :delay="500">
          <Button icon-left="pencil" variant="primary" @click="modalEditStage(props.row)" />
        </o-tooltip>
      </div>
    </o-table-column>
    <o-table-column v-slot="{ row }" width="24" field="details">
      <div class="more-info" @click="toggleDetails(row)">
        <template v-if="JSON.parse(row.documents).length">
          <o-icon :icon="row.isOpen ? 'chevron-up' : 'chevron-down'" size="14px" />
        </template>
      </div>
    </o-table-column>
    <template #detail="props">
      <div class="documents">
        <h3 v-if="JSON.parse(props.documents).length" class="mt-3">
          Lista de documentos requeridos
        </h3>
        <div class="filesList">
          <p
            v-for="(doc, idx) in JSON.parse(props.documents)"
            :key="`document-${idx}`"
            class="files"
            :class="isDocumentRequired(doc)"
          >
            {{ masksFormat(doc.document_name, doc.file_masks) }}
          </p>
        </div>
      </div>
    </template>
  </Table>
</template>
<script>
import { ref, computed, getCurrentInstance, onMounted, reactive, watch } from 'vue';
import { Dropdown, Field, Table, Button, ModalStagesMonthlyRun } from '@/components';
import { useStages } from '@/utils/composables';

export default {
  components: {
    Button,
    Dropdown,
    Field,
    ModalStagesMonthlyRun,
    Table,
  },
  setup() {
    const header = 'header';
    const apiUrl = ref('stages');
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { getOmissibleNameByID, OMISSIBLE, formatedDoc, isDocumentRequired } = useStages();
    const bookMarksItems = ref([{ label: 'Omisible', param: OMISSIBLE }, { label: 'Obligatorio' }]);
    const fieldSearch = reactive({ oldValue: '', value: '', timeout: null });
    const itemSelected = ref(null);
    const isModalAddStageOpen = ref(false);
    const isEdit = ref(false);
    const isStage = ref(false);
    const tableStages = ref(null);
    const isLoading = ref(false);
    const isLoadingDropdown = ref(false);
    const rowData = ref([]);

    const dropdownItems = ref([
      {
        name: 'Ejecución',
        items: [
          { name: 'Omisible', params: 'is_omissible=1' },
          { name: 'Obligatorio', params: 'is_omissible=0' },
        ],
      },
    ]);

    const masksFormat = (documentName, fileMasks) => {
      fileMasks = fileMasks.replace(/[[\]']+/g, '');
      if (fileMasks.length < 5) return `${documentName} ${fileMasks}`;
      return `${documentName}`;
    };

    const getDropdownsItems = async () => {
      try {
        const { data } = await Api.get('/stage-types');
        const stageTypes = data.map((type) => ({
          name: type.name,
          params: `stage_type_id=${type.id}`,
        }));
        dropdownItems.value = [
          ...dropdownItems.value,
          { name: 'Tipo de la etapa', items: [...stageTypes] },
        ];
      } catch (error) {
        console.log(error);
      }
    };

    const preprocesor = (value, firstValue = false) => {
      if (!value) return [];
      const data = JSON.parse(value);
      if (firstValue && data) return data.type;
      return data.length ? data : false;
    };
    const params = computed(() => {
      let eParams = [];
      if (itemSelected.value && itemSelected.value.params != 'clear')
        eParams.push(itemSelected.value.params);
      if (fieldSearch.oldValue.length) eParams.push(`name=${fieldSearch.oldValue}`);
      return eParams;
    });

    const modalNewStage = () => {
      rowData.value = '[]';
      isModalAddStageOpen.value = true;
      isStage.value = true;
    };

    const modalEditStage = (data) => {
      isModalAddStageOpen.value = true;
      isEdit.value = true;
      isStage.value = true;
      rowData.value = data;
    };

    const toggleDetails = (row) => {
      if (tableStages.value) {
        row.isOpen = !row.isOpen;
        tableStages.value.oTable.toggleDetails(row);
      }
    };

    const reload = async () => {
      if (tableStages.value) {
        await tableStages.value.reload();
      }
    };

    onMounted(() => {
      getDropdownsItems();
    });

    watch(itemSelected, (value, oldValue) => {
      if (value?.name && value?.name != 'Borrar seleccion' && oldValue == null)
        dropdownItems.value.unshift({
          name: '',
          items: [{ name: 'Borrar selección', params: 'clear' }],
        });
      else if (value?.params === 'clear') {
        dropdownItems.value.shift();
        itemSelected.value = null;
      }
    });

    watch(
      () => fieldSearch.value,
      (value) => {
        clearTimeout(fieldSearch.timeout);
        fieldSearch.timeout = setTimeout(() => {
          fieldSearch.oldValue = value;
        }, 500);
      },
    );

    return {
      apiUrl,
      bookMarksItems,
      dropdownItems,
      fieldSearch,
      header,
      isEdit,
      isLoading,
      isLoadingDropdown,
      isModalAddStageOpen,
      isStage,
      itemSelected,
      params,
      rowData,
      tableStages,
      masksFormat,

      // Methods
      formatedDoc,
      getDropdownsItems,
      getOmissibleNameByID,
      isDocumentRequired,
      modalEditStage,
      modalNewStage,
      preprocesor,
      toggleDetails,
      reload,
    };
  },
};
</script>
<style lang="sass" scoped>
#table-planned
  min-height: 500px

:deep(.b-table)
  td
    text-align: center
.search
  width: 550px
  @media screen and (max-width: $bp-md)
    width: 100%
  @media screen and (max-width: $bp-sm)
    :deep(.field.has-addons)
          display: flex
          flex-direction: column
          .dropdown .dropdown-trigger .button
            border-radius: 8px
            margin-bottom: 10px
          .control .input
            border-radius: 8px !important

  :deep(.button)
    height: 35px
.documents
  display: flex
  flex-direction: column
  align-items: start
  margin: 20px
  .filesList
    display: inline-flex
    .files
      margin-right: 20px
      &.required::after
          color: $color-radical-red
          content: '*'
  h3
    font-weight: 600
  p.doc.required::after
    color: $color-radical-red
    content: '*'
  p.doc::before
    content: ''
    display: inline-block
    width: 6px
    height: 6px
    background-color: $black
    margin: 0 3px 1.5px 0
    border-radius: 100%
  p.no-description
    font-weight: 700
    color: $grey-400
.user-actions
  display: flex
  width: 74px
  align-items: center
  justify-content: center
  margin: auto
  :deep(.button)
    border-radius: 3px
    height: 24px
    width: 24px
    padding: 0
    .icon
      margin: 0 !important
      .mdi::before
        font-size: 18px
  :deep(.b-tooltip) .tooltip-content
    font-size: 12px
    padding: 6px
  :deep(.more-info:hover)
    cursor: pointer
</style>
