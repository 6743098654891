<template>
  <Modal
    id="ModalErrorRedirect"
    v-model:is-open="isModalActive"
    header="Error"
    :save-need="false"
    :button-label-cancel="props.buttonMessage"
    :can-cancel="false"
    @cancel="onClose"
  >
    <h2 v-if="props.errorHeader" class="error-header">{{ props.errorHeader }}</h2>
    <p class="error-message">{{ props.errorMessage }}</p>
  </Modal>
</template>

<script setup>
import Modal from '../Modal.vue';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

//variables
const router = useRouter();
const props = defineProps({
  errorHeader: {
    type: String,
    default: '',
  },
  errorMessage: {
    type: String,
    default: 'Error inesperado. Si el problema persiste, por favor contacte a soporte',
  },
  redirectUrl: {
    type: String,
    default: '',
  },
  buttonMessage: {
    type: String,
    default: 'Cerrar',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

//methods
const onClose = () => {
  if (props.redirectUrl) {
    router.push(props.redirectUrl);
  }
};

//computed
const isModalActive = computed(() => props.isOpen);
</script>

<style scoped lang="sass">
#ModalErrorRedirect
    .error-message, .error-header
        color: $black
    .error-header
        font-size: $fh-sm
        font-weight: 600
</style>
