import { emailRegex, rfcRegex, fileNameRegex } from './RegexValidations';
import * as yup from 'yup';

export const emailSchema = yup
  .string()
  .required('Completa este campo')
  .max(255)
  .matches(emailRegex, 'Correo electrónico inválido');
export const clientNameSchema = yup.string().required('Completa este campo').max(255);
export const tinSchema = yup
  .string()
  .required('Completa este campo')
  .matches(rfcRegex, 'RFC inválido');
export const selectSchema = yup.string().required('Completa este campo');
export const entityTypeSchema = yup
  .string()
  .required('Selecciona una opción')
  .oneOf(['PERSONA FISICA', 'PERSONA MORAL'], 'Selecciona una opción');

export const stageTypeSchema = yup
  .string()
  .required('Selecciona una opción')
  .oneOf(
    [
      'Positiva / Negativa / Fecha',
      'Positiva / Negativa',
      'Ejecución por Fecha',
      'Archivos',
      'Archivos / Fecha',
      'Ejecución',
    ],
    'Selecciona una opción',
  );

export const responsibleEntitieSchema = yup
  .string()
  .required('Selecciona una opción')
  .oneOf(['USUARIO', 'CLIENTE'], 'Selecciona una opción');
export const phoneNumberSchema = yup
  .string()
  .required('Completa este campo')
  .min(14, 'Número de teléfono inválido');
export const TenDigitPhoneNumberSchema = yup
  .string()
  .notRequired()
  .min(10, 'Número de teléfono inválido');
export const BooleanSchema = yup.number().transform((value) => (value ? 1 : 0));
export const DaySchema = yup.string('Tiene que ser un día válido').required('Completa este campo');
export const fileNameSchema = yup
  .string()
  .required('Completa este campo')
  .matches(fileNameRegex, 'Nombre de archivo inválido')
  .max(255, 'Nombre de archivo máximo de 255 caracteres.');
export const yesNoRadioSchema = yup
  .string()
  .required('Completa este campo')
  .oneOf(['0', '1'], 'Selección no válida');

export const serviceAreaSchema = yup
  .string()
  .required('Selecciona un elemento')
  .oneOf(['Contabilidad', 'Auditoria'], 'Selecciona un elemento');

export const userTypeSchema = yup
  .string()
  .required('Selecciona un elemento')
  .oneOf(['Administrador', 'Auditor', 'Operador', 'Cliente'], 'Selecciona un elemento');
export const schemas = {
  BooleanSchema,
  clientNameSchema,
  tinSchema,
  entityTypeSchema,
  fileNameSchema,
  phoneNumberSchema,
  emailSchema,
  selectSchema,
  stageTypeSchema,
  responsibleEntitieSchema,
  DaySchema,
  yesNoRadioSchema,
  serviceAreaSchema,
  userTypeSchema,
  TenDigitPhoneNumberSchema,
};
