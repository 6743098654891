<template>
  <o-tabs v-model="selected" class="bookmarks" :type="type">
    <o-tab-item
      v-for="(item, key) in oData"
      :key="key"
      class="border-color"
      :label="isLoading.counter || !item.labelText ? item[model] : item.labelText"
      :icon="item.icon"
      :disabled="item?.disabled || isLoading.data || isLoading.counter || !item.totalItems"
    />
  </o-tabs>
</template>
<script>
import { toRefs, watch, ref, computed, getCurrentInstance, onBeforeMount } from 'vue';
export default {
  props: {
    apiUrl: { type: [String, Function], default: '' },
    counter: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    model: { type: String, default: 'label' },
    params: { type: Array, default: () => [] },
    type: { type: String, default: 'default' },
  },
  emits: ['update:item', 'update:loading', 'update:totals'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const selected = ref(1);
    const { loading, params, data } = toRefs(props);
    const oData = ref(props.data);
    const isLoading = ref({ data: props.loading, counter: false });
    const cancelToken = ref(null);
    const timeout = ref(null);
    const totalSum = ref(0);

    const cancelRequest = (message = 'Avoid multiple') => {
      isLoading.value.counter = true;
      if (cancelToken.value) {
        cancelToken.value.cancel(message);
      }
    };

    const queryParams = computed(() => {
      const aParams = [...params.value];
      return `?${aParams.join('&')}`;
    });

    const getTotal = async (total, param) => {
      try {
        let aParams = [queryParams.value];
        if (props.counter) aParams.push(param);
        if (total) aParams.push('all_indicators=True');
        aParams = aParams.join('&');
        const { data } = await Api.get(`/${props.apiUrl}/total-items${aParams}`, {
          cancelToken: cancelToken.value.token,
        });
        totalSum.value = Object.values(data)
          .map((item) => item.total_items)
          .reduce((acc, value) => acc + value, 0);
        emit('update:totals', { ...data, totalSum: totalSum.value });
        return (data && data[0]) || data;
      } catch (error) {
        console.error(error);
      }
    };
    const setLabelCounter = async () => {
      isLoading.value.counter = true;
      cancelRequest();
      clearTimeout(timeout.value);
      cancelToken.value = Api.cancelToken;
      timeout.value = setTimeout(async () => {
        const totalItems = await getTotal(true);

        oData.value = await Promise.all(
          oData.value.map(async (item) => {
            const itemTotalItems = item.value
              ? totalItems[item.value]?.total_items
              : totalSum.value;
            return {
              ...item,
              totalItems: itemTotalItems,
              labelText: `${item[props.model]} (${itemTotalItems ? itemTotalItems : 0})`,
            };
          }),
        );
        isLoading.value.counter = false;
      }, 160);
    };
    const reload = () => setLabelCounter();
    watch(queryParams, async () => {
      await setLabelCounter();
    });
    watch(loading, (value) => (isLoading.value.data = value));
    watch(isLoading, (value) => emit('update:loading', value.data));
    watch(data, (value) => (oData.value = value));
    watch(oData, (value) => emit('update:loading', value));
    watch(selected, async (value) => {
      await setLabelCounter();
      emit('update:item', oData.value[value - 1]);
    });
    onBeforeMount(async () => {
      await setLabelCounter();
    });
    return {
      selected,
      isLoading,
      oData,
      queryParams,
      reload,
    };
  },
};
</script>
<style lang="sass" scoped>
.bookmarks
  margin: 0 auto !important
  width: calc( 100% - 1px )
  :deep(.tabs)
    border-bottom: 0 !important
    &.is-boxed > div
      border-top: 8px solid #92999E
      background: $white
      box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.24)
      border-radius: 6px 6px 0px 0px
      margin-right: 8px
      a
        border-radius: 0
        border: none
        margin-bottom: 0
        padding: 8px 24px
        .icon
          width: auto
          margin-right: 6px !important
          .mdi::before
            color: $black
            font-size: 20px
          ~ span
            font-weight: 600
            font-size: $f-sm
        &.is-active
          background: $black
          .icon ~ span
            color: $white
          .icon .mdi::before
            color: $white
  :deep(.tab-content)
    display: none
    padding: 0px
</style>
