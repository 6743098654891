<template>
  <Modal
    id="modal-client-asociate"
    v-model:is-open="isModalActive"
    :header="headerTitle"
    :save-need="false"
    :loading="isLoading"
    button-label-cancel="Cerrar"
  >
    <div class="is-flex mb-4">
      <template v-if="isActiveForm">
        <form ref="form" class="is-flex is-flex-direction-column w-100">
          <h3 class="has-text-weight-bold fh-sm has-text-dark is-uppercase">{{ formTitle }}</h3>
          <hr class="mt-0 divider" />
          <div class="columns mb-0">
            <Field
              :key="`field-mame-${currentForm}`"
              v-model="associateData.name"
              placeholder="Ingresar nombre"
              label="Nombre:"
              class="column mb-0"
              text-transform="upper"
              :disabled="isViewToDelete || isViewToEdit"
              required
            />
            <Field
              :key="`field-email-${currentForm}`"
              v-model="associateData.email"
              placeholder="ejemplo@correo.com"
              label="Email:"
              class="column mb-0"
              :pattern="emailRegex"
              type="email"
              :disabled="isViewToDelete || isViewToEdit"
              required
              :variant="emailFieldHandler.variant"
              :message="emailFieldHandler.message"
            />
          </div>
          <div class="columns">
            <Select
              v-model="associateData.role"
              label="Rol:"
              :disabled="isViewToDelete || isViewToEdit"
              model="role"
              required
              class="role-select column mb-0"
              :data="[{ role: associateRole.RESPONSABLE }, { role: associateRole.SUPERVISOR }]"
            />
            <CheckableDropdown
              :key="`field-service-${currentForm}`"
              v-model:selected-options="servicesSelected"
              v-model:oData="serviceData"
              class="column"
              label="Servicio(s):"
              placeholder="Seleccionar servicio(s)"
              :api="{ url: `clients/${data.id}/services`, params: ['is_active=1'] }"
              model="service_name"
              :variant="errorCheckableHandler.variant"
              :message="errorCheckableHandler.message"
              required
              unique-value="service_id"
              :disabled="isViewToDelete"
            />
          </div>

          <div class="form-actions ml-auto">
            <Button
              label="Cancelar"
              class="has-text-weight-semibold"
              :disabled="isLoading.save"
              @click="closeForm"
            />
            <Button
              v-if="isViewToDelete"
              class="ml-4 save-button"
              label="Eliminar usario"
              icon-left="delete"
              :disabled="isLoading.save"
              variant="danger"
              @click="deleteUser"
            />
            <Button
              v-else
              class="ml-4 save-button has-text-weight-semibold"
              :label="`Guardar ${isAssociateEditing ? 'cambios' : ''}`"
              icon-left="check-circle-outline"
              :outlined="isDisabled"
              :loading="isLoading.save"
              :disabled="isDisabled"
              :variant="isDisabled ? 'default' : 'primary'"
              @click="onSumbit"
            />
          </div>
          <hr class="line-jump" />
        </form>
      </template>
      <Button
        v-if="!isActiveForm"
        class="ml-auto"
        label="Nuevo usario"
        icon-left="plus"
        :disabled="isLoading.table || isLoading.save"
        variant="primary"
        @click="isActiveForm = true"
      />
    </div>
    <TableUserAsociates
      ref="tableAsociates"
      v-model:loading="isLoading.table"
      :class="isActiveForm ? 'table-scroll' : ''"
      :customer-id="data.id"
      @on-edit-associate="onEditAssociate"
      @on-delete-associate="onDeleteAssociate"
    />
  </Modal>
</template>
<script>
import { Modal, Button, Field, CheckableDropdown, Select } from '@/components';
import TableUserAsociates from '../Tables/TableUserAsociates.vue';
import useDialog from '@/utils/composables/useDialog';
import { emailRegex } from '@/utils/RegexValidations';
import { computed, reactive, ref, toRefs, watch, getCurrentInstance } from 'vue';
export default {
  components: { CheckableDropdown, Modal, TableUserAsociates, Button, Field, Select },
  props: {
    isOpen: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
  },
  emits: ['update:active', 'update:is-open', 'update:data'],
  setup(props, { emit }) {
    const form = ref(null);
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { Notify } = useDialog();
    const tableAsociates = ref(null);
    const isModalActive = ref(props.isOpen);
    const isActiveForm = ref(false);
    const { isOpen, data } = toRefs(props);
    const userData = ref(props.data);
    const servicesSelected = ref([]);
    const serviceData = ref([]);
    const isLoading = reactive({ table: false, save: false });
    const isViewToDelete = ref(false);
    const isViewToEdit = ref(false);
    const associateRole = {
      SUPERVISOR: 'SUPERVISOR',
      RESPONSABLE: 'RESPONSABLE',
    };
    const associateData = ref({
      role: associateRole.RESPONSABLE,
    });
    const emailFieldHandler = reactive({
      variant: undefined,
      message: '',
    });
    const errorCheckableHandler = reactive({
      variant: undefined,
      message: '',
    });
    const setSeletedsOptions = (userAsociate) => {
      servicesSelected.value = JSON.parse(
        userAsociate.associate_services ? userAsociate.associate_services : '[]',
      ).map(({ id, name }) => ({ service_id: id, service_name: name }));
    };
    const onDeleteAssociate = (userAsociate) => {
      isViewToDelete.value = true;
      isViewToEdit.value = false;
      associateData.value = userAsociate;
      setSeletedsOptions(userAsociate);
      isActiveForm.value = true;
    };
    const onEditAssociate = (userAsociate) => {
      isViewToDelete.value = false;
      isViewToEdit.value = true;
      associateData.value = userAsociate;
      setSeletedsOptions(userAsociate);
      isActiveForm.value = true;
    };
    const resetForm = () => {
      isViewToDelete.value = false;
      isViewToEdit.value = false;
      associateData.value = {
        role: associateRole.RESPONSABLE,
      };
      servicesSelected.value = [];
    };
    const closeForm = () => {
      tableAsociates.value.selected = {};
      isActiveForm.value = false;
      resetForm();
    };
    const validate = () => {
      let htmlValidator = false;
      if (form.value) {
        htmlValidator = form.value.checkValidity();
        if (!htmlValidator) form.value.reportValidity();
        if (!servicesSelected.value.length) {
          htmlValidator = false;
          errorCheckableHandler.message = 'Seleccioné un servicio';
          errorCheckableHandler.variant = 'danger';
        }
      }
      return htmlValidator;
    };
    const onSumbit = async () => {
      if (!validate()) return;
      isLoading.save = true;
      try {
        const { name, email, role } = associateData.value;
        if (!isViewToEdit.value) {
          const { data: newDataAssociate } = await Api.post(
            `/users/clients/${userData.value.id}/associates`,
            {
              service_id: servicesSelected.value[0].service_id,
              full_name: name,
              role: role,
              email: email,
            },
          );
          associateData.value.id = newDataAssociate.id;
        }
        let promises = { delete: [], post: [] };
        const servicesSelectedsId = servicesSelected.value.map((s) => s.service_id);
        const oldAssociateServicesid = JSON.parse(
          associateData.value.associate_services ?? '[]',
        ).map((s) => s.id);
        const deleteServices = [];
        const postServices = [];
        serviceData.value.forEach((service) => {
          const isSelected = servicesSelectedsId.includes(service.service_id);
          const serviceId = service.id;
          const serviceActive = oldAssociateServicesid.includes(service.service_id);
          if ((isSelected && !serviceActive) || (isSelected && !isViewToEdit.value)) {
            postServices.push({
              path: `/clients/services/${serviceId}/associates`,
              data: { customer_associates_id: associateData.value.id },
            });
          } else if (serviceActive && !isSelected && isViewToEdit.value)
            deleteServices.push({
              path: `/clients/services/${serviceId}/associates/${associateData.value.id}`,
            });
        });
        const updateService = async ({ path, data }, method) => {
          const myPromise = Api[method](path, data ? data : []);
          promises[method].push(myPromise);
          return await myPromise;
        };
        await Promise.all(postServices.map((service) => updateService(service, 'post')));
        await Promise.all(deleteServices.map((service) => updateService(service, 'delete')));
        tableAsociates.value?.reload();

        Notify(
          'success',
          isViewToEdit.value ? 'Usuario editado exitosamente' : 'Usuario agregado exitosamente',
          `Cliente: ${associateData.value.name}`,
        );
        isActiveForm.value = false;
        resetForm();
      } catch (error) {
        if (error.data?.status === 'El correo ya está registrado') {
          emailFieldHandler.message = 'Ingresar un correo válido o no repetido';
          emailFieldHandler.variant = 'danger';
        }
        console.log(error);
      }
      isLoading.save = false;
    };
    const deleteUser = async () => {
      isLoading.save = true;
      try {
        const { customer_id, id } = associateData.value;
        await Api.delete(`clients/${customer_id}/associates/${id}`);
        tableAsociates.value?.reload();
        Notify(
          'success',
          'Usuario eliminado exitosamente',
          `Cliente: ${associateData.value.name.toUpperCase()}`,
        );
        resetForm();
      } catch (error) {
        console.log(error);
      }
      isLoading.save = false;
    };
    const setServices = (_data) => {
      const responsible = _data.map((value) => ({
        ...value,
        service_name: `${value.service_name} - RESPONSABLE`,
        service_role: 'RESPONSABLE',
      }));
      const supervisor = _data.map((value) => ({
        ...value,
        service_name: `${value.service_name} - SUPERVISOR`,
        service_role: 'SUPERVISOR',
      }));
      return [...responsible, ...supervisor];
    };
    const checkConditon = (service, selecteds) => {
      const idx = selecteds.findIndex(
        (op) => service.id === op.id && service.service_role === op.service_role,
      );
      return idx;
    };
    const disabledRoleServiceSelected = (service, selecteds) => {
      const existService = selecteds.map((op) => op.id).includes(service.id);
      return (
        existService &&
        selecteds.findIndex(
          (op) => service.id === op.id && service.service_role !== op.service_role,
        ) > -1
      );
    };

    const headerTitle = computed(() => `Usuarios - ${props.data.name}`);
    const formTitle = computed(() => {
      if (isViewToDelete.value) return '¿Deseas eliminar el siguiente usuario?';
      else if (isViewToEdit.value) return 'Editar usuario';
      return 'Agregar usuario';
    });
    const currentForm = computed(() => {
      if (isViewToDelete.value) return 'Eliminar';
      else if (isViewToEdit.value) return 'Editar';
      return 'Agregar';
    });
    const isAssociateEditing = computed(() => isActiveForm.value && associateData.value.id);
    const isDisabled = computed(() => {
      const { table, save } = isLoading;
      const { name, email } = associateData.value;
      const somethingIsLoading = table || save;
      const dataEmpty = !name || !email;
      return somethingIsLoading || dataEmpty;
    });

    watch(isModalActive, resetForm);
    watch(data, (value) => (userData.value = value));
    watch(userData, (value) => emit('update:data', value));
    watch(
      () => associateData.value.email,
      () => {
        if (emailFieldHandler.message.length) {
          emailFieldHandler.message = '';
          emailFieldHandler.variant = undefined;
        }
      },
    );
    watch(
      servicesSelected,
      (value) => {
        if (value.length) {
          errorCheckableHandler.message = '';
          errorCheckableHandler.variant = undefined;
        }
      },
      { deep: true },
    );
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) {
        closeForm();
        resetForm();
      }
    });
    return {
      // data
      associateData,
      form,
      isModalActive,
      isLoading,
      isActiveForm,
      tableAsociates,
      emailRegex,
      emailFieldHandler,
      errorCheckableHandler,
      servicesSelected,
      isViewToDelete,
      isViewToEdit,
      associateRole,
      serviceData,

      // Method
      onDeleteAssociate,
      onSumbit,
      deleteUser,
      closeForm,
      setServices,
      checkConditon,
      disabledRoleServiceSelected,
      onEditAssociate,

      // Computed
      currentForm,
      formTitle,
      headerTitle,
      isAssociateEditing,
      isDisabled,
    };
  },
};
</script>
<style lang="sass" scoped>
@include from($bp-sm)
  .line-jump
    display: none
@include until($bp-sm)
  .form-actions
    :deep(button)
      margin: 0 0 10px 0 !important
#modal-client-asociate
    .role-select :deep(select)
      max-height: 35px
    :deep(button).save-button
        min-width: 134px
        &:disabled
            background-color: $color-disable
            color: $grey-dark
    .divider
        background-color: $black
        height: 1px
    :deep(.modal-content)
        max-width: 996px !important
        width: 100%
        overflow: hidden
        .card .card-content
          @include until($bp-md)
            max-height: 40vh
    .table-scroll :deep(.b-table)
      .table-wrapper
        max-height: 200px
        overflow: auto
        position: relative
        thead
          position: sticky
          top: 0
          z-index: 1
</style>
