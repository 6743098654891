<template>
  <Modal
    id="modal-pago-factura"
    v-model:is-open="isModalActive"
    size="is-medium"
    :header="header"
    :loading="loading"
    :save-disabled="isDisabled"
    :button-label="billData.id ? 'Guardar' : 'Agregar'"
    @save="onSumbit"
    @cancel="resetForm"
  >
    <form ref="form">
      <p class="f-sm has-text-weight-bold has-text-dark mb-2 w-1">
        Tipo<span class="chart"></span>:
      </p>
      <div class="is-flex mb-4">
        <Radio
          v-if="!billData.id || (billData.id && data.document_type === DOCUMENT_TYPE.PAGO.key)"
          v-model="documentTypeSelected"
          class="mr-4"
          native-value="P"
          label="Pago:"
          name="document-type"
        />
        <Radio
          v-if="!billData.id || (billData.id && data.document_type === DOCUMENT_TYPE.FACTURA.key)"
          v-model="documentTypeSelected"
          native-value="I"
          label="Factura:"
          name="document-type"
        />
      </div>
      <div class="columns mb-1">
        <Field
          v-model="billData.monto"
          v-maska="bindedObject"
          class="column mb-0 has-addons-left"
          placeholder="Ingresar"
          label="Monto:"
          required
          data-maska="N.##"
          data-maska-tokens="N:[0-9]:multiple"
        >
          <template #addonsLeft>
            <div class="monto-addon has-addons-right">$</div>
          </template>
        </Field>

        <div v-if="documentTypeSelected === DOCUMENT_TYPE.PAGO.key" class="column">
          <!-- <o-checkbox v-model="existBill" root-class="radio-document-type">
            Pagar factura existente
          </o-checkbox> -->
        </div>
        <div v-if="documentTypeSelected === DOCUMENT_TYPE.FACTURA.key" class="column">
          <Field
            v-model="billData.folio"
            class="mb-0"
            maxlength="15"
            text-transform="upper"
            placeholder="Ingresar folio"
            label="Folio:"
          />
          <p class="ml-auto counter-field mt-1">{{ montoLenght }}</p>
        </div>
      </div>
      <Autocomplete
        v-if="documentTypeSelected === DOCUMENT_TYPE.PAGO.key && existBill"
        class="mb-4"
        required
        :data-pre-processor="dataPreProcessor"
        label="Factura a pagar:"
        placeholder="Buscar factura por folio o descripción"
        field="compose_name"
        :api="{
          url: `clients/${clientId}/bills`,
          query: 'all',
          params: [{ id: 'document_type', value: 'I' }],
        }"
        :variant="hasErrorType ? autocompleteErrorHandler.variant : ''"
        :message="hasErrorType ? autocompleteErrorHandler.message : ''"
        @error:not-selected="onError(autocompleteTypes.TYPE)"
        @select-raw="onSelect"
      />
      <Field
        v-model="billData.description"
        label="Descripción de la factura:"
        type="textarea"
        maxlength="100"
        placeholder="Descripción"
      />
      <p class="ml-auto counter-field mt-1">{{ descriptionLenght }}</p>
    </form>
  </Modal>
</template>
<script>
import { toRefs, ref, watch, getCurrentInstance, computed, reactive } from 'vue';
import { useDialog, useBills } from '@/utils/composables';
import { Autocomplete, Radio, Modal, Field } from '@/components';
import { clone } from '@/utils/Ramda';

export default {
  components: {
    Autocomplete,
    Modal,
    Radio,
    Field,
  },
  props: {
    clientId: { type: String, default: '' },
    isOpen: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
  },
  emits: ['update:active', 'update:is-open', 'succes'],
  setup(props, { emit }) {
    const { DOCUMENT_TYPE } = useBills();
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { isOpen, data } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const form = ref(null);
    const loading = reactive({ save: false });
    const documentTypeSelected = ref(DOCUMENT_TYPE.PAGO.key);
    const existBill = ref(documentTypeSelected.value === DOCUMENT_TYPE.PAGO.key);
    const { Notify } = useDialog();
    const billData = ref(clone(props.data));
    const autocompleteErrorHandler = reactive({
      targets: [],
      message: 'Seleccione una opción',
      variant: 'danger',
    });
    const bindedObject = reactive({});
    const autocompleteTypes = {
      TYPE: Symbol('TYPE'),
    };

    const validate = () => {
      let htmlValidator = false;
      if (form.value) {
        htmlValidator = form.value.checkValidity();
        if (!htmlValidator) form.value.reportValidity();
      }
      return htmlValidator;
    };
    const onSumbit = async () => {
      if (!validate()) return;
      loading.save = true;
      try {
        const { PAGO, FACTURA } = DOCUMENT_TYPE;
        const urlHandler = billData.value.id
          ? { method: 'put', url: `/bills/${billData.value.id}` }
          : { method: 'post', url: `clients/${props.clientId}/bills` };
        const body = {
          ...billData.value,
          document_type: billData.value.document_type
            ? billData.value.document_type
            : documentTypeSelected.value,
          customer_id: props.clientId,
        };
        const monto = billData.value.monto;

        delete body.id;
        delete body.customer_id;
        delete body.monto;

        if (documentTypeSelected.value === PAGO.key) {
          if (!existBill.value) delete billData.value.related_uuid;
          body.credit = parseInt(monto);
        } else if (documentTypeSelected.value === FACTURA.key) body.charge = parseInt(monto);

        await Api[urlHandler.method](urlHandler.url, body);
        isModalActive.value = false;
        if (billData.value.id) Notify('success', `Edición completada exitosamente`);
        else {
          if (documentTypeSelected.value == PAGO.key)
            if (existBill.value)
              Notify(
                'success',
                `Factura pagada exitosamente`,
                `Factura: ${billData.value.folio ?? ''}  ${
                  ' - ' + (billData.value.description ?? '')
                }`,
              );
            else Notify('success', `Pago registrado exitosamente`, `Pago ${monto ?? ''}`);
          else
            Notify(
              'success',
              `Factura registrada exitosamente`,
              `Factura: ${billData.value.folio ?? ''}  ${
                ' - ' + (billData.value.description ?? '')
              }`,
            );
        }
        emit('succes');
      } catch (error) {
        console.log(error);
      }
      loading.save = false;
    };
    const onError = (_target, isValid) => {
      const { targets } = autocompleteErrorHandler;
      if (isValid) autocompleteErrorHandler.targets = targets.filter((a) => a !== _target);
      else targets.push(_target);
    };

    const dataPreProcessor = (fields) => {
      return fields.map((value) => ({
        compose_name: `${value.folio ?? ''} - ${value.description ?? ''}`,
        ...value,
      }));
    };
    const onSelect = (value) => {
      if (value) billData.value.related_uuid = value.bill_uuid;
    };
    const isEditing = computed(() => Boolean(props.data.id));
    const header = computed(() => `${billData.value.id ? 'Editar' : 'Nuevo'} pago/factura`);
    const resetForm = () => {
      const id = billData.value.id;
      // existBill.value = false;
      billData.value = { id };
    };
    const onUpdateMonto = (value) => {
      const _monto = String(value).split('.');
      if (_monto[1]?.length > 2) {
        const newValue = `${_monto[0]}.${_monto[1].slice(0, 2)}`;
        return newValue;
      }
      return value;
    };
    const montoLenght = computed(() => `${billData.value.folio?.length ?? 0} / 15`);
    const descriptionLenght = computed(() => `${billData.value.description?.length ?? 0} / 100`);
    const hasErrorType = computed(
      () =>
        autocompleteErrorHandler.targets.filter((target) => target == autocompleteTypes.TYPE)
          .length,
    );
    const isDisabled = computed(() => {
      const { monto, related_uuid } = billData.value;
      const hasRelatedUuid = existBill.value ? related_uuid?.length : true;
      return Boolean(!monto || !hasRelatedUuid);
    });

    watch(documentTypeSelected, () => {
      if (!data.value.id) resetForm();
      existBill.value = documentTypeSelected.value === DOCUMENT_TYPE.PAGO.key;
    });
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      const { PAGO } = DOCUMENT_TYPE;
      emit('update:active', value);
      emit('update:is-open', value);
      if (value) {
        billData.value = clone(data.value);
        documentTypeSelected.value = data.value.document_type ?? PAGO.key;
        billData.value.monto = data.value.charge ?? data.value.credit;
        // existBill.value = Boolean(billData.value.related_uuid);
      } else {
        resetForm();
        documentTypeSelected.value = PAGO.key;
      }
    });

    return {
      // data
      autocompleteErrorHandler,
      autocompleteTypes,
      billData,
      bindedObject,
      documentTypeSelected,
      DOCUMENT_TYPE,
      existBill,
      form,
      isModalActive,
      loading,

      // Computed
      hasErrorType,
      header,
      isDisabled,
      isEditing,
      montoLenght,
      descriptionLenght,

      // Methods
      onError,
      onSelect,
      onUpdateMonto,

      // Functions
      dataPreProcessor,
      onSumbit,
      resetForm,
    };
  },
};
</script>
<style lang="sass" scoped>
#modal-pago-factura
  :deep(.card)
    .card-content
      overflow: visible
      @include until($bp-sm)
        max-height: 45vh
        overflow: scroll
      .radio-document-type
        display: flex
        align-items: flex-end
        height: 100%
      .monto-addon
        border-radius: 5px 0px 0px 5px
        background: $grey-light
        border: 1px solid $grey-light-2
        padding: 5px 10px
        color: $grey-info
  .chart::after
    color: $color-radical-red
    content: '*'
</style>
