<template>
  <Modal
    id="ModalAuditoria"
    v-model:is-open="isModalActive"
    :button-label="headerTitle"
    :loading="loading"
    :header="headerTitle"
    :save-disabled="isDisabled || !isEdited"
    size="is-large"
    @save="onSubmit"
  >
    <form ref="form" @submit.prevent="onSubmit">
      <Autocomplete
        required
        label="Selecciona el cliente:"
        :disabled="onEdit"
        :placeholder="onEdit ? auditingData.customer_name : 'Selecciona el cliente'"
        field="customer_name"
        :api="{
          url: '/clients/services',
          query: 'customer_name',
          full: true,
          params: [
            { id: 'service_name', value: 'Auditoria' },
            { id: 'is_active', value: 1 },
          ],
        }"
        :variant="hasErrorClient ? autocompleteErrorHandler.variant : ''"
        :message="hasErrorClient ? autocompleteErrorHandler.message : ''"
        @select-raw="onSelect"
        @error:not-selected="onError(autocompletes.CLIENT, !$event)"
      />
      <Field
        v-model="auditingData.name"
        maxlength="50"
        label="Nombre de la auditoría"
        placeholder="Nombre de la auditoría"
        required
      />
      <div class="is-flex is-justify-content-space-between mb-4 data-picker">
        <DatePicker
          v-model="auditingData.start_date"
          required
          class="w-33"
          label="Fecha de inicio"
          :max-date="auditingData.end_date ? auditingData.end_date : undefined"
        />
        <DatePicker
          v-model="auditingData.end_date"
          required
          class="w-33"
          label="Fecha de término"
          :min-date="auditingData.start_date ? auditingData.start_date : undefined"
        />
        <Autocomplete
          :value="typeAuditing"
          required
          class="w-33"
          label="Tipo de auditoría"
          placeholder="Selecciona tipo"
          field="name"
          :api="{
            url: '/auditing/types',
            query: 'name',
            full: true,
          }"
          :variant="hasErrorType ? autocompleteErrorHandler.variant : ''"
          :message="hasErrorType ? autocompleteErrorHandler.message : ''"
          @select-raw="onSelectType"
          @error:not-selected="onError(autocompletes.TYPE, !$event)"
        />
      </div>
      <Field
        v-model="auditingData.description"
        label="Descripción de la auditoría"
        placeholder="Escribe la descripción para la auditoría"
        type="textarea"
        required
        maxlength="500"
      />
      <p class="counter-field">{{ textLength }} / 500</p>
    </form>
  </Modal>
</template>
<script>
import { Modal, Autocomplete, Field, DatePicker } from '@/components';
import { useComponentUtils } from '@/components/conf/composables';
import { reactive, ref, getCurrentInstance, watch, toRefs, onBeforeMount, computed } from 'vue';
import moment from 'moment';
import useDialog from '@/utils/composables/useDialog';

export default {
  components: {
    Autocomplete,
    DatePicker,
    Modal,
    Field,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    data: {
      type: Object,
      default: () => ({
        name: null,
        description: '',
        start_date: null,
        end_date: null,
        status: 'CREADA',
        customer_service_id: null,
        auditing_type_id: null,
      }),
    },
  },
  emits: ['update:active', 'update:is-open', 'update:data', 'succes:save'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { Notify } = useDialog();
    const loading = reactive({ save: false, serviceId: false });
    const autocompletes = {
      CLIENT: Symbol('CLIENT'),
      TYPE: Symbol('TYPE'),
    };
    const typeAuditing = ref('');
    const { table } = useComponentUtils();
    const form = ref(null);
    const { isOpen, data } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const auditingData = reactive(props.data);
    const initialData = ref({});
    const autocompleteErrorHandler = reactive({
      targets: [],
      message: `Seleccione una opción`,
      variant: 'danger',
    });

    const serviceId = ref('');

    const validate = () => {
      let htmlValidator = false;
      if (form.value) {
        htmlValidator = form.value.checkValidity();
        if (!htmlValidator) form.value.reportValidity();
      }
      return htmlValidator;
    };
    const reload = async () => {
      if (table.value) {
        await table.value.reload();
      }
    };

    const onSubmit = async () => {
      if (!validate()) return;
      loading.save = true;
      try {
        const {
          id,
          start_date,
          end_date,
          auditing_type_id,
          customer_service_id,
          name,
          description,
          status,
        } = auditingData;
        await Api[`${onEdit.value ? 'put' : 'post'}`](`/auditing${onEdit.value ? '/' + id : ''}`, {
          start_date: moment(start_date).format('YYYY-MM-DD'),
          end_date: moment(end_date).format('YYYY-MM-DD'),
          auditing_type_id,
          customer_service_id,
          name,
          description,
          status: status ?? 'CREADA',
        });

        await reload();
        if (onEdit.value)
          Notify(
            'success',
            'Auditoría editada',
            `La auditoría ${auditingData.name} fue editada de manera exitosa`,
          );
        else
          Notify(
            'success',
            'Auditoría creada',
            `La auditoría ${auditingData.name} fue creada de manera exitosa`,
          );
        isModalActive.value = false;
      } catch (error) {
        console.log(error);
      }
      loading.save = false;
    };
    const onSelect = (value) => (auditingData.customer_service_id = value?.id);
    const onError = (_target, isValid) => {
      const { targets } = autocompleteErrorHandler;
      if (isValid)
        autocompleteErrorHandler.targets = targets.filter(
          (autocomplete) => autocomplete !== _target,
        );
      else targets.push(_target);
    };
    const onSelectType = (value) => (auditingData.auditing_type_id = value?.id);
    const resetForm = () => {
      const initialValues = [
        'name',
        'description',
        'start_date',
        'end_date',
        'customer_service_id',
        'auditing_type_id',
      ];
      for (const key in auditingData) {
        if (initialValues.includes(key)) {
          if (key == 'description' || key == 'name') auditingData[key] = '';
          else auditingData[key] = null;
        } else delete auditingData[key];
      }
      typeAuditing.value = '';
      autocompleteErrorHandler.targets = [];
      initialData.value = {};
    };
    const getServiceId = async () => {
      loading.serviceId = true;
      try {
        const { data } = await Api.get(`/services`);
        serviceId.value = data.find((service) => service.name === 'Auditoria')?.id;
      } catch (error) {
        console.log(error);
      }
      loading.serviceId = false;
    };
    const textLength = computed(() =>
      auditingData.description ? auditingData.description.length : 0,
    );
    const isDisabled = computed(
      () =>
        !Object.values(auditingData).every((v) => v != null && (v.length || moment(v).isValid())),
    );
    const onEdit = computed(() => Boolean(auditingData.id));
    const headerTitle = computed(() => (onEdit.value ? 'Editar auditoría' : 'Añadir auditoría'));
    const hasErrorClient = computed(
      () =>
        autocompleteErrorHandler.targets.filter((target) => target == autocompletes.CLIENT).length,
    );
    const hasErrorType = computed(
      () =>
        autocompleteErrorHandler.targets.filter((target) => target == autocompletes.TYPE).length,
    );
    const isEdited = computed(() => {
      return onEdit.value
        ? auditingData.name !== initialData.value.name ||
            auditingData.description !== initialData.value.description ||
            auditingData.start_date !== initialData.value.start_date ||
            auditingData.end_date !== initialData.value.end_date ||
            auditingData.customer_service_id !== initialData.value.customer_service_id ||
            auditingData.auditing_type_id !== initialData.value.auditing_type_id
        : true;
    });
    watch(
      () => auditingData?.customer_service_id,
      (value) => {
        if (value) onError(autocompletes.CLIENT, true);
      },
    );
    watch(
      () => auditingData?.auditing_type_id,
      (value) => {
        if (value) onError(autocompletes.TYPE, true);
      },
    );
    watch(isOpen, (value) => {
      isModalActive.value = value;
      if (value) {
        initialData.value = { ...props.data };
      }
    });
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) resetForm();
    });
    watch(data, (value) => {
      for (const key in value) {
        auditingData[key] = value[key];
      }
    });
    watch(auditingData, (value) => {
      emit('update:data', value);
    });
    watch(
      onEdit,
      () => {
        if (onEdit.value) {
          typeAuditing.value = auditingData.auditing_type_name;
        }
      },
      { immediate: true },
    );
    onBeforeMount(async () => await getServiceId());

    return {
      typeAuditing,
      autocompletes,
      hasErrorClient,
      hasErrorType,
      autocompleteErrorHandler,
      auditingData,
      form,
      onEdit,
      headerTitle,
      onError,
      isDisabled,
      isModalActive,
      loading,
      onSelect,
      onSelectType,
      onSubmit,
      serviceId,
      table,
      textLength,
      isEdited,
    };
  },
};
</script>
<style lang="sass" scoped>
#ModalAuditoria
  :deep(.textarea)
    height: 150px
  :deep(.modal-content), :deep(.animation-content), .card-content
    overflow: visible
  @media screen and (max-width: $bp-md)
    :deep(.card-content)
      max-height: 40vh
      overflow: scroll
  .date-picker :deep(.datepicker)
    width: 100%
    > .dropdown > .dropdown-trigger .input
      padding-left: 32px
  .w-60
    width: calc( 60% - 8px )
  .w-40
    width: calc( 40% - 8px )
  .w-33
    width: calc( 33% - 8px )
  @media screen and (max-width:$bp-sm)
    :deep(.data-picker)
      display: inline-block !important
      > *
        width: unset !important
</style>
