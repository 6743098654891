<template>
  <ModalClient
    v-model:is-open="modalHandler.CLIENTS.isActive"
    v-model:data="modalHandler.CLIENTS.data"
    @success:save="reload"
  />
  <ModalClientAsociates
    v-model:is-open="modalHandler.CLIENTS_ASSOCIATES.isActive"
    v-model:data="modalHandler.CLIENTS_ASSOCIATES.data"
  />
  <Table
    id="table-client"
    ref="tableClient"
    v-model:loading="isLoading"
    :api-url="apiUrl"
    header="CLIENTES"
    :focusable="false"
    paginated
    :params="params"
  >
    <template #header>
      <FilterDropown
        class="responsable-filter"
        icon-left="bank"
        :is-active="responsibleCustomerName.length"
        :header-title="filterHeader"
      >
        <template #body>
          <Autocomplete
            open-on-focus
            :max-height="225"
            :api="{
              url: '/users',
              query: 'full_name',
              params: [{ id: 'is_active', value: 1 }],
              full: true,
            }"
            clearable
            keep-open
            :set-first-item="false"
            :select-on-click-outside="false"
            :min-to-search="1"
            class="mb-0"
            field="full_name"
            placeholder="Seleccionar responsable"
            @select-raw="onSelect"
            @clear="onClear"
          />
        </template>
      </FilterDropown>
      <Field
        v-model="fieldSearch.value"
        class="search"
        :placeholder="placeholderSearch"
        icon-right="magnify"
        text-transform="upper"
      >
        <template #addonsLeft>
          <Dropdown
            v-model:selected="itemSelected"
            label="Campos de búsqueda"
            variant="info"
            :data="dropdownItems"
          />
        </template>
      </Field>
    </template>
    <o-table-column
      v-slot="{ row }"
      sortable
      label="Nombre cliente"
      field="name"
      sort-icon-size="large"
    >
      <p class="is-uppercase has-text-centered">
        {{ row.name }}
      </p>
      <TagServices :data="row.services" />
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="Datos de contacto"
      sort-icon-size="large"
      field="email"
      :width="200"
    >
      <p class="has-text-centered">
        {{ props.row.phone_number ? formatPhoneNumber(props.row.phone_number) : '-' }}
      </p>
      <p v-if="props.row.email" class="has-text-centered">
        {{ props.row.email || '-' }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="RFC"
      sort-icon-size="large"
      width="150"
      field="tin"
    >
      <p class="has-text-centered">
        {{ props.row.tin }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="Régimen fiscal"
      sort-icon-size="large"
      field="tax_regime_id"
    >
      <p class="has-text-centered">
        {{ props.row.tax_regime_name }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="props"
      sortable
      label="Persona"
      sort-icon-size="large"
      field="entity_type"
      :width="100"
    >
      <p class="has-text-centered mr-2">
        {{ getEntityType(props.row.entity_type) }}
      </p>
    </o-table-column>
    <o-table-column v-slot="{ row }" label="Responsables" field="responsible_user" :width="200">
      <ul
        v-for="(responsible, id) in row?.responsible_user ? JSON.parse(row.responsible_user) : []"
        :key="id"
        class="has-text-centered"
      >
        <li class="mb-2">{{ responsible?.full_name }}</li>
      </ul>
    </o-table-column>
    <o-table-column v-slot="{ row }" label="Acciones" field="is_active" :width="150">
      <ClientActions
        v-model:loading="isLoading"
        :reload="reload"
        :data="row"
        @on-edit-client="onEditModal(row, modalHandler.CLIENTS.target)"
        @on-edit-associates="onEditModal(row, modalHandler.CLIENTS_ASSOCIATES.target)"
      />
    </o-table-column>
  </Table>
</template>
<script>
import { ref, toRefs, watch, onMounted, computed, reactive } from 'vue';
import {
  Table,
  ModalClient,
  Field,
  Dropdown,
  ModalClientAsociates,
  TagServices,
  FilterDropown,
  Autocomplete,
} from '@/components';
import { useComponentUtils } from '@/components/conf/composables';
import ClientActions from './FragmentsClients/ClientActions';
export default {
  components: {
    ClientActions,
    Dropdown,
    Field,
    ModalClient,
    ModalClientAsociates,
    Table,
    TagServices,
    FilterDropown,
    Autocomplete,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['update:loading'],
  setup(props, { emit }) {
    const tableClient = ref(null);
    const { table, formatPhoneNumber } = useComponentUtils();
    const responsibleCustomerName = ref('');
    const responsibleCustomerId = ref(null);
    const loading = toRefs(props).loading;
    const isLoading = ref(props.loading);
    const apiUrl = ref('clients');
    const modalHandler = reactive({
      CLIENTS: {
        target: Symbol('CLIENTS'),
        isActive: false,
        data: {},
      },
      CLIENTS_ASSOCIATES: {
        target: Symbol('CLIENTS ASSOCIATES'),
        isActive: false,
        data: {},
      },
    });
    const isModalEditClientActive = ref(false);
    const isActiveClientAsociates = ref(false);
    const fullClient = reactive({ value: '', timeout: null });
    const fieldSearch = reactive({ oldValue: '', value: '', timeout: null });
    const dropdownItems = [
      { name: 'Nombre', params: 'name' },
      { name: 'RFC', params: 'tin' },
      { name: 'Correo', params: 'email' },
      { name: 'Teléfono', params: 'phone_number' },
    ];
    const itemSelected = ref(dropdownItems[0]);

    const params = computed(() => {
      let eParams = [];
      const { params } = itemSelected.value;
      if (fieldSearch.oldValue.length) {
        eParams.push(`${params}=${fieldSearch.oldValue}`);
      }
      if (responsibleCustomerId.value)
        eParams.push(`responsible_user_id=${responsibleCustomerId.value}`);
      return eParams;
    });
    const reload = async () => {
      if (tableClient.value) await tableClient.value.reload(false);
    };
    const onEditModal = (userData, target) => {
      const { CLIENTS, CLIENTS_ASSOCIATES } = modalHandler;
      if (CLIENTS_ASSOCIATES.target === target) {
        CLIENTS_ASSOCIATES.data = { ...userData };
        CLIENTS_ASSOCIATES.isActive = true;
      } else if (CLIENTS.target === target) {
        CLIENTS.data = { ...userData };
        CLIENTS.isActive = true;
      }
    };
    const filterHeader = computed(() => {
      return responsibleCustomerName.value.length ? responsibleCustomerName.value : 'Responsable';
    });
    const onSelect = (value) => {
      if (value) {
        responsibleCustomerName.value = value?.full_name;
        responsibleCustomerId.value = value?.id;
      }
    };

    const onClear = () => {
      responsibleCustomerName.value = '';
      responsibleCustomerId.value = null;
    };

    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => {
      emit('update:loading', value);
    });
    watch(
      () => fieldSearch.value,
      (value) => {
        clearTimeout(fieldSearch.timeout);
        fieldSearch.timeout = setTimeout(() => {
          fieldSearch.oldValue = value;
        }, 500);
      },
    );
    onMounted(() => {
      if (tableClient.value) table.value = tableClient.value;
    });
    return {
      isActiveClientAsociates,
      isLoading,
      isActive: (is_active) => {
        return is_active ? 'Activo' : 'Inactivo';
      },
      isModalEditClientActive,
      modalHandler,
      onEditModal,
      apiUrl,
      tableClient,
      params,
      reload,
      table,
      formatPhoneNumber,
      placeholderSearch: computed(() => `Buscar por ${itemSelected.value.name}`),
      itemSelected,
      getEntityType: (entity_type) => (entity_type == 'PERSONA FISICA' ? 'Física' : 'Moral'),
      fullClient,
      filterHeader,
      fieldSearch,
      dropdownItems,
      responsibleCustomerName,
      onSelect,
      onClear,
    };
  },
};
</script>

<style lang="sass" scoped>
#table-client
    :deep(.table-title)
      .responsable-filter
        min-width: 300px
        margin: 0 20px 0 auto
        height: 35px
        @media screen and (max-width: $bp-md)
          margin: 0
          min-width: unset
        > :deep(.dropdown-content)
          min-height: 300px
          .dropdown-content
            box-shadow: unset
            display: block !important
      .search
        width: 550px
        @media screen and (max-width: $bp-md)
          width: 100%
        .button
          height: 35px
    :deep(.table)
      width: calc( 100% - 6px )
      thead tr
        th
            padding: 13.4px 0
            &:first-child > span
              margin: 0 auto
              max-width: 174px
            &:nth-child(2)
              width: 200px
      tbody tr td
        word-wrap: break-word
        padding: 6px 3px
        &:first-child
          padding-left: 24px
          white-space: break-spaces
        &:nth-child(3)
          padding: 6px
          max-width: 150px
        @media screen and (max-width: $bp-sm)
          display: inline-block
          text-align: left
          padding: 6px 10px !important
</style>
