<template>
  <div :class="classes">
    <o-dropdown
      ref="dropdown"
      v-model="dropdownOptions"
      multiple
      aria-role="list"
      :disabled="Boolean(isLoading || disabled)"
      class="checkable-dropdown-element w-100"
      :position="position"
      :close-on-click="false"
      :triggers="[]"
      @active-change="onActiveDropdown"
    >
      <template #trigger>
        <Field
          v-model="searchField"
          :disabled="Boolean(isLoading || disabled)"
          :skeleton="isLoading"
          :model-value="searchable ? searchField : field"
          :label="label"
          :placeholder="placeholder"
          :required="required"
          icon-right="chevron-down"
          :error-message="isLoading ? '' : errorMessage"
          :readonly="!searchable"
          @click="toggleDropdown(true)"
          @blur="$emit('blur', $event)"
        />
      </template>
      <template v-for="(option, idx) in filterData" :key="`option-00${idx}`">
        <o-dropdown-item :value="option" aria-role="listitem" :clickable="false">
          <o-checkbox
            :model-value="
              (checkConditonFunction
                ? checkConditonFunction(option, dropdownOptions)
                : getOptionIdx(option)) > -1
            "
            :disabled="disabledFuntion ? disabledFuntion(option, dropdownOptions) : false"
            @update:model-value="onSelectOption(option, $event)"
          >
            <span class="is-uppercase">{{ option[model] }}</span>
          </o-checkbox>
        </o-dropdown-item>
      </template>
      <template v-if="!filterData.length">
        <template v-if="$slots.empty">
          <slot name="empty" />
        </template>
        <p v-else class="px-2">No se encontraron datos</p>
      </template>
    </o-dropdown>
  </div>
</template>
<script>
import { ref, toRefs, watch, getCurrentInstance, computed, onMounted } from 'vue';
import { Field } from '@/components';
import { equals } from '@/utils/Ramda';
export default {
  components: { Field },
  props: {
    api: { type: Object, default: () => ({ query: null, params: [] }) },
    dataPreProcessor: { type: Function, default: null },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    model: { type: String, default: 'name' },
    oData: { type: Array, default: () => [] },
    placeholder: { type: String, default: '' },
    required: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false },
    selectedOptions: { type: Array, default: () => [] },
    uniqueValue: { type: String, default: 'id' },
    variant: { type: String, default: undefined },
    message: { type: String, default: '' },
    disabledFuntion: { type: Function, default: undefined },
    checkConditonFunction: { type: Function, default: undefined },
    position: { type: String, default: 'bottom-right' },
    searchable: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
  },
  emits: ['update:selected-options', 'update:oData', 'update:loading', 'blur'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const dropdown = ref(null);
    const { selectedOptions, oData, loading } = toRefs(props);
    const dropdownOptions = ref(props.selectedOptions);
    const options = ref([]);
    const searchField = ref('');
    const isLoading = ref(props.loading);

    const setOptions = (oData) => {
      const { dataPreProcessor } = props;
      if (dataPreProcessor) oData = dataPreProcessor(oData);
      options.value = oData;
    };
    const toggleDropdown = (isOpen) => (dropdown.value.isActive = isOpen);
    const getOptionIdx = (option) =>
      dropdownOptions.value.findIndex((op) => option[props.uniqueValue] === op[props.uniqueValue]);
    const onSelectOption = (option, checkValue) => {
      const itemIdx = props.checkConditonFunction
        ? props.checkConditonFunction(option, dropdownOptions.value)
        : getOptionIdx(option);
      if (itemIdx < 0 && checkValue) dropdownOptions.value.push(option);
      else dropdownOptions.value.splice(itemIdx, 1);
    };
    const getData = async () => {
      const { api } = props;
      isLoading.value = true;
      try {
        if (!api.url) return;
        const { data } = await Api.get(`${api.url}${params.value}`);
        setOptions(data);
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    };

    const onActiveDropdown = (value) => (searchField.value = value ? '' : field.value);
    const field = computed(() => {
      const { uniqueValue, model } = props;
      const itemsToShow = dropdownOptions.value.map((option) => {
        const item = options.value.find((_option) => option[uniqueValue] === _option[uniqueValue]);
        return item ? item[model] : '';
      });
      return options.value.length ? itemsToShow.filter((_model) => !!_model.length).join(', ') : '';
    });
    const classes = computed(() => {
      const classes = ['checkable-dropdown'];
      if (props.horizontal) classes.push('is-horizontal');
      return classes.join(' ');
    });
    const filterData = computed(() => {
      if (!props.searchable) return options.value;
      const find = new RegExp(`${searchField.value}.*`, 'i');
      return options.value.filter((o) => find.test(o[props.model]));
    });
    const params = computed(() => {
      const { api } = props;
      return api.params ? `?${api.params.join('&')}` : '';
    });

    watch(dropdownOptions, (value) => {
      if (!equals(value, dropdownOptions.value)) emit('update:selected-options', value);
    });
    watch(selectedOptions, (value) => (dropdownOptions.value = value));
    watch(options, (value) => {
      emit('update:oData', value);
    });
    watch(oData, (value) => (options.value = value));
    watch(isLoading, (value) => emit('update:loading', value));
    watch(loading, (value) => (isLoading.value = value));
    watch(field, (value) => {
      if (!dropdown.value.isActive && value.length) onActiveDropdown(false);
    });

    onMounted(() => {
      const { api, oData } = props;
      if (api.url) getData();
      else if (oData.length) setOptions(oData);
    });
    return {
      // data
      dropdownOptions,
      options,
      isLoading,
      searchField,
      dropdown,

      // computed
      classes,
      filterData,
      field,

      // methods
      getOptionIdx,
      onActiveDropdown,
      onSelectOption,
      toggleDropdown,
    };
  },
};
</script>
<style lang="sass" scoped>
.checkable-dropdown
    .checkable-dropdown-element
        width: 100%
        &.is-disabled
          opacity: 1
          :deep(.dropdown-trigger)
            .label
              color: $black
            .control
              opacity: .7
        :deep(.dropdown-trigger)
            width: 100%
        :deep(.dropdown-content)
            width: fit-content
            .dropdown-item
                cursor: unset
                &.is-active
                    background-color: inherit
                    color: $black
    &.is-horizontal :deep(.dropdown-trigger) > .field
      display: flex
      align-items: center
      > .label
       margin-bottom: 0
       margin-right: 10px
       height: fit-content
      @media screen and (max-width: $bp-md)
        .b-skeleton
          min-width: 120px
      > .control, .b-skeleton
       width: 397px
       @media screen and (max-width: $bp-md)
         width: 100%
      .help.is-danger
        position: absolute
        right: 5px
        bottom: -19px
</style>
