<template>
  <article class="file-controller columns is-1 is-variable">
    <Field
      v-bind="fileNameFieldAttrs"
      v-model="fileNameField"
      class="column"
      type="text"
      maxlength="255"
      required
      :error-message="errors.fileNameField"
      placeholder="Nombre archivo"
    />
    <CheckableDropdown
      v-bind="extencionFieldAttrs"
      v-model:selected-options="extencionField"
      class="column is-one-fifth"
      :error-message="errors.extencionField"
      placeholder="Extensión"
      :o-data="extencionArchivos"
      unique-value="name"
      required
    />
    <o-field class="column is-1">
      <p class="required-message">¿Requerido?</p>
      <o-checkbox v-bind="requiredFieldAttrs" v-model="requiredField" />
    </o-field>
  </article>
</template>
<script setup>
/* eslint-disable no-unused-vars */
import useStages from '@/utils/composables/useStages';
import { useForm } from 'vee-validate';
import { Field, CheckableDropdown } from '@/components';
import { schemas } from '@/utils/YupSchemas';
import * as yup from 'yup';
import { toRefs, watch } from 'vue';

const props = defineProps({
  fileName: { type: String, default: undefined },
  extencion: { type: Array, default: () => [] },
  required: { type: [Boolean, Number], default: false },
});
const emit = defineEmits([
  'update:file-name',
  'update:extencion',
  'update:required',
  'update:valid',
]);

const { extencionArchivos } = useStages();
const { fileName, extencion, required } = toRefs(props);

const { errors, setValues, defineField, meta } = useForm({
  initialValues: {
    fileNameField: props.fileName,
    extencionField: props.extencion,
    requiredField: props.required,
  },
  validationSchema: yup.object({
    fileNameField: schemas.fileNameSchema,
    extencionField: yup.array().min(1, 'Necesita seleccionar mínimo una'),
  }),
});

const [fileNameField, fileNameFieldAttrs] = defineField('fileNameField');
const [extencionField, extencionFieldAttrs] = defineField('extencionField');
const [requiredField, requiredFieldAttrs] = defineField('requiredField');

watch(fileName, (value) => setValues({ fileNameField: value }));
watch(fileNameField, (value) => emit('update:file-name', value));
watch(extencion, (value) => setValues({ extencionField: value }));
watch(extencionField, (value) => emit('update:extencion', value), { deep: true });
watch(required, (value) => setValues({ requiredField: value }));
watch(requiredField, (value) => emit('update:required', value));
watch(
  () => meta.value.valid,
  (value) => emit('update:valid', value),
);
</script>
<style lang="sass" scoped>
.file-controller
  margin-bottom: 0
  .field:last-child :deep(.field.has-addons)
    .required-message
        display: none
  .column
    margin-bottom: 0
    &.is-1 >
      .b-checkbox
        margin: 0
        width: 100%
        justify-content: center
        margin-top: 6px
        :deep(.check)
          padding: 6px
@media screen and (max-width: $bp-md)
  .file-controller
    position: relative
    &::before
      content: ''
      position: absolute
      bottom: 0
      left: 0
      width: 110%
      height: 1px
      background-color: $grey-light
    .checkable-dropdown
      width: 140px
    .field:first-child
      width: 190px
      margin-top: 20px
    .field:last-child :deep(.field.has-addons)
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: 10px
      justify-content: start
      .required-message
        display: flex
        font-weight: 600
      .checkbox
        margin: 0 0 0 40px
</style>
