<template>
  <Modal
    v-if="isModalActive"
    id="modalUpdateMonthly"
    v-model:isOpen="isModalActive"
    header="Agregar corrida mensual"
    size="is-large"
  >
    <div class="datePicker">
      <DatePicker v-model="monthYear" label="Fecha" type="month" inline />
    </div>
    <template #footer>
      <Button label="Cancelar" variant="danger" :disabled="isSaving" inverted @click="onCancel" />
      <Button label="Guardar" variant="primary" :loading="isSaving" @click="updateMonthyRun" />
    </template>
  </Modal>
</template>

<script>
import { ref, toRefs, watch, computed } from 'vue';
import useDialog from '@/utils/composables/useDialog';
import { Button, Modal, DatePicker } from '@/components';
import moment from 'moment';
import Api from '@/utils/Api.js';
export default {
  components: {
    Button,
    Modal,
    DatePicker,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    data: { type: String, default: '' },
  },
  emits: ['update:is-open'],
  setup(props, { emit }) {
    // Constants
    const lastMonth = moment().add(-1, 'month').toDate();

    //Variables
    const { isOpen } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const monthYear = ref(lastMonth);
    const isSaving = ref(false);
    const { Notify } = useDialog();

    //Computed
    const formatedDate = computed(() => {
      const date = moment(monthYear.value);
      return {
        month: date.format('M'),
        fiscal_year: date.format('YYYY'),
      };
    });

    //Métodos
    const onCancel = () => {
      isModalActive.value = false;
    };

    const updateMonthyRun = async () => {
      isSaving.value = true;
      const postMonthyData = {
        customer_id: props.data.clientId,
        service_id: props.data.serviceId,
        ...formatedDate.value,
      };
      try {
        await Api.post('/monthly-run', postMonthyData);
        Notify('success', 'Éxito', `Corrida agregada`);
        isModalActive.value = false;
      } catch (error) {
        console.log(error);
      }
      isSaving.value = false;
    };

    //Watch
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:is-open', value);
    });

    return {
      isModalActive,
      isSaving,
      monthYear,
      formatedDate,
      onCancel,
      updateMonthyRun,
    };
  },
};
</script>

<style lang="sass" scoped>
.datePicker
  display: flex
  justify-content: center
</style>
