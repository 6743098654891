<template>
  <header class="header-bills">
    <h1 class="header-title mb-2">FACTURACIÓN</h1>
    <div class="is-flex is-flex-wrap-wrap filters-table">
      <Tag
        class="tag-balance has-text-weight-semibold mr-auto mb-3"
        size="large"
        custom-color="E3E3E3"
      >
        <span>Balance general: </span>
        <o-icon v-if="isLoading.balance" icon="loading" spin size="small" class="mx-3" />
        <span v-else :class="`ml-2 ${tagBalance.color} `">{{ tagBalance.value }}</span>
      </Tag>
      <Field
        v-model="fieldSearch.value"
        class="field-search"
        placeholder="Buscar"
        icon-right="magnify"
        text-transform="upper"
      >
        <template #addonsLeft>
          <Dropdown
            v-model:selected="itemSelected"
            label="Campos de búsqueda"
            variant="info"
            :data="dropdownItems"
          />
        </template>
      </Field>
      <Dropdown
        class="custome-filter ml-2"
        hide-menu-icon
        icon-left="filter"
        label="Filtros"
        variant="primary"
        position="bottom-left"
      >
        <ul class="list-filter fh-xs">
          <li v-for="item in filterItems" :key="item.key" class="filter-item-list">
            <CheckBox
              v-model="item.checked"
              :label="item.name"
              :name="item.name"
              :native-value="item.key"
            />
          </li>
        </ul>
      </Dropdown>
    </div>
    <div
      v-if="hasFiltersActive"
      class="tag-container is-flex is-justify-content-flex-end is-flex-wrap-wrap"
    >
      <template v-for="item in filterItems" :key="`tag-${item.key}`">
        <Tag v-if="item.checked" class="tag-filter mb-2" rounded variant="link">
          <o-icon
            class="button-delete-filter"
            icon="close-circle-outline"
            role="button"
            size="small"
            @click="() => (item.checked = false)"
          />
          <span> {{ item.name }} </span>
        </Tag>
      </template>
    </div>
  </header>
  <Table
    id="table-bills"
    ref="tableBills"
    v-model:loading="isLoading.data"
    :api-url="apiUrl"
    :focusable="false"
    paginated
    :per-page="8"
    :params="params"
  >
    <o-table-column
      v-slot="{ row }"
      sortable
      width="433"
      label="Nombre cliente"
      field="customer_name"
      sort-icon-size="large"
    >
      <p class="is-uppercase">
        {{ row.customer_name }}
      </p>
      <div v-if="!row.is_active_bills" class="is-flex">
        <Tag label="inactivo" class="mr-auto" rounded />
      </div>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      width="158"
      sortable
      label="RFC"
      field="customer_tin"
      sort-icon-size="large"
    >
      <p class="is-uppercase has-text-centered">
        {{ row.customer_tin }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      width="330"
      sortable
      label="Régimen fiscal"
      field="customer_regime"
      sort-icon-size="large"
    >
      <p class="is-uppercase has-text-centered">
        {{ row.customer_regime }}
      </p>
    </o-table-column>
    <o-table-column
      v-slot="{ row }"
      width="255"
      sortable
      label="Última facturación y pago"
      field="last_bill"
      sort-icon-size="large"
      :th-attrs="() => ({ class: 'break-space' })"
    >
      <p class="is-uppercase has-text-centered">
        {{ fomatDateDMY(row.last_bill) }}
      </p>
    </o-table-column>
    <o-table-column v-slot="{ row }" width="163" label="Balance total" field="customer_balance">
      <p class="has-text-centered">
        <span
          :class="`ml-2 has-text-weight-bold  ${
            row.customer_balance >= 0 ? 'has-text-primary' : 'has-text-danger'
          }`"
          >{{ dollarFormater(row.customer_balance || 0) }}</span
        >
      </p>
    </o-table-column>
    <o-table-column v-slot="{ row }" width="140">
      <Button
        class="bill-button has-text-weight-semibold"
        icon-left="receipt-text"
        label="Detalle"
        variant="primary"
        size="small"
        @click="onNavigate(row.customer_id)"
      />
    </o-table-column>
  </Table>
</template>
<script>
import { ref, toRefs, watch, onMounted, reactive, computed, getCurrentInstance } from 'vue';
import { Table, Button, Tag, Field, Dropdown, CheckBox } from '@/components';
import { useComponentUtils } from '@/components/conf/composables';
import { useRouter } from 'vue-router';

export default {
  components: {
    Button,
    CheckBox,
    Table,
    Tag,
    Field,
    Dropdown,
  },
  inheritAttrs: false,
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['update:loading'],
  setup(props, { emit }) {
    const tableBills = ref(null);
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const balance = ref(NaN);
    const { table, fomatDateDMY, dollarFormater } = useComponentUtils();
    const router = useRouter();
    const loading = toRefs(props).loading;
    const isLoading = reactive({ data: props.loading, balance: false });
    const apiUrl = ref('bills');
    const data = ref({});
    const fieldSearch = reactive({ oldValue: '', value: '', timeout: null });
    const dropdownItems = ref([
      { name: 'Nombre cliente', param: 'customer_name' },
      { name: 'RFC', param: 'customer_tin' },
      { name: 'Régimen fiscal', param: 'customer_regime' },
    ]);
    const filterItems = reactive({
      //Client Service Auditing
      CSA: {
        key: 'CSA',
        name: 'Clientes con auditoría',
        query: 'customer_service',
        value: 'Auditoría',
        checked: false,
      },
      //Client Service Contabilidad
      CSC: {
        key: 'CSC',
        name: 'Clientes con contabilidad',
        query: 'customer_service',
        value: 'Contabilidad',
        checked: false,
      },
      //Negative balance
      NB: {
        key: 'NB',
        name: 'Sólo carteras vencidas',
        query: 'negative_balance',
        value: '1',
        checked: false,
      },
      //Active bills
      AB: {
        key: 'AB',
        name: 'Facturación activa',
        query: 'is_active_bills',
        value: '1',
        checked: false,
      },
      //inactive bills
      IB: {
        key: 'IB',
        name: 'Facturación inactiva',
        query: 'is_active_bills',
        value: '0',
        checked: false,
      },
    });
    const itemSelected = ref(dropdownItems.value[0]);
    const reload = async () => {
      if (tableBills.value) await tableBills.value.reload();
    };
    const onNavigate = (customerId) => router.push({ path: `/facturacion/${customerId}` });

    const getBalance = async () => {
      isLoading.balance = true;
      try {
        const { data } = await Api.get(`clients/balance`);
        balance.value = data.general_balance;
      } catch (error) {
        console.log(error);
      }
      isLoading.balance = false;
    };
    const params = computed(() => {
      const { CSA, CSC, AB, IB } = filterItems;
      const filters = Object.values(filterItems);
      const filterByService = [];
      const filterByStatusBills = [];
      const _params = [];
      filters.forEach(({ key, value, checked, query }) => {
        if (!checked) return;
        if ([CSA.key, CSC.key].includes(key)) filterByService.push(`${query}=${value}`);
        else if ([AB.key, IB.key].includes(key)) filterByStatusBills.push(`${query}=${value}`);
        else _params.push(`${query}=${value}`);
      });
      if (filterByService.length === 1) _params.push(filterByService[0]);
      if (filterByStatusBills.length === 1) _params.push(filterByStatusBills[0]);
      if (fieldSearch.oldValue.length)
        _params.push(`${itemSelected.value.param}=${fieldSearch.oldValue}`);
      return _params;
    });
    const tagBalance = computed(() => {
      let color =
        balance.value >= 0 || isNaN(balance.value) ? 'has-text-primary' : 'has-text-danger';
      return {
        color: color,
        value: dollarFormater(balance.value || 0),
      };
    });
    const hasFiltersActive = computed(() => {
      const { AB, CSA, CSC, IB, NB } = filterItems;
      return [AB.checked, CSA.checked, CSC.checked, IB.checked, NB.checked].includes(true);
    });

    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => {
      emit('update:loading', value);
    });
    watch(
      () => fieldSearch.value,
      (value) => {
        clearTimeout(fieldSearch.timeout);
        fieldSearch.timeout = setTimeout(() => (fieldSearch.oldValue = value), 500);
      },
    );

    onMounted(() => {
      if (tableBills.value) table.value = tableBills.value;
      getBalance();
    });
    return {
      //Data
      apiUrl,
      data,
      dropdownItems,
      fieldSearch,
      isLoading,
      reload,
      table,
      tableBills,
      itemSelected,
      filterItems,

      // metodos
      dollarFormater,
      fomatDateDMY,
      getBalance,
      onNavigate,

      // computed
      params,
      hasFiltersActive,
      tagBalance,
    };
  },
};
</script>

<style lang="sass" scoped>
.header-bills
  margin-bottom: 32px
  .filters-table
    place-content: end
    :deep(.dropdown) .dropdown-content
      z-index: 1
  .tag-filter
    &:not(:last-child)
      margin-right: 10px
    .button-delete-filter
      &:hover
        cursor: pointer
  .custome-filter
    margin-bottom: 8px
    height: 37px
    :deep(.dropdown-trigger) .button
      height: 37px
      padding: 4px 16px
      .icon .mdi-filter::before
        font-size: 16px
    :deep(.dropdown-menu)
      margin-top: 4px
      width: 266px
      .list-filter
        padding: 0px 8px
        .filter-item-list
          padding: 8px 8px
          .b-checkbox
            font-size: $f-sm
            .check:not(:checked)
              border-color: $grey-dark
          .control-label
            padding-left: 8px
  .field-search
    margin-bottom: 16px
    width: 100%
    max-width: 600px
    min-width: 300px
    :deep(.control) .has-addons
      .dropdown .button
        max-height: 37px
      > .control input
          height: 100%
  .header-title
    font-weight: 700
    color: $black
    font-size: $fh-md
    line-height: 28px
@include until($bp-md)
  .tag-container
    justify-content: flex-start !important
  .tag-balance
    order: 0
  .field-search
    order: 1
  .custome-filter
    order: 0
    margin-bottom: 16px
#table-bills
  :deep(.b-table) .table-wrapper
    thead tr

      th
        padding: 13px
        vertical-align: middle
        &.break-space span
          white-space: break-spaces
        &:first-child
          padding-left: 24px
          span
            justify-content: left
    tbody
      .bill-button
        padding: 5px 16px
        border-radius: 9px
        .button-wrapper
          align-items: center
          .icon
            width: fit-content
            margin-right: 7px
            .mdi::before
              font-size: 16px
      @include until($bp-sm)
        td
          display: inline-block !important
          text-align: left
</style>
