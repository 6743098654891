<template>
  <div class="register-form">
    <div v-if="isPostValid" class="post-submit">
      <p class="head">gracias</p>
      <p class="body">
        Hemos enviado un correo electrónico con las credenciales de inicio de sesión a
        <span>{{ email }}</span>
      </p>
      <p class="sub-body">
        Con estos datos podrás hacer uso de todas la funciones de nuestro sistema.
      </p>
      <Button
        variant="primary"
        label="Iniciar sesión"
        rounded
        class="register-button"
        @click="emit('return')"
      />
    </div>
    <div v-else class="pre-submit">
      <div class="welcome-text">
        <h2>CREA TU CUENTA</h2>
        <p>¡Gracias por unirte a nuestra plataforma!</p>
        <p>Para continuar con tu registro, por favor completa los siguientes datos:</p>
      </div>
      <Field
        v-model="name"
        v-bind="nameAttrs"
        :error-message="errors.name"
        :disabled="isLoading"
        placeholder="Ingresa tu nombre completo"
        label="Nombre completo"
        maxlength="255"
        required
      />
      <Field
        v-model="email"
        v-bind="emailAttrs"
        :error-message="errors.email"
        maxlength="255"
        placeholder="Ejemplo:correo@ejemplo.com"
        :pattern="emailRegex"
        :disabled="isLoading"
        label="Correo electrónico"
        type="email"
        required
      />
      <Field
        v-model="phone"
        v-bind="phoneAttrs"
        v-maska="phoneNumberMaska"
        :error-message="errors.phone"
        data-maska="##########"
        placeholder="Ejemplo: 3124567890"
        label="Teléfono"
        :disabled="isLoading"
        maxlength="10"
      />
      <p class="sub-phone">El número debe contener 10 dígitos</p>

      <Button
        variant="primary"
        label="Crear cuenta"
        rounded
        class="register-button"
        :disabled="!isFormValid || isLoading"
        :loading="isLoading"
        @click="onSubmit"
      />
      <p v-if="!isLoading" class="return-login">
        ¿Ya tienes cuenta?
        <span class="link-blue" @click="emit('return')">Inicia sesión</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import Field from './Field.vue';
import Button from './Button.vue';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { clientNameSchema, emailSchema, TenDigitPhoneNumberSchema } from '@/utils/YupSchemas';
import { computed, defineEmits, getCurrentInstance, ref, watch, reactive } from 'vue';
import { emailRegex } from '@/utils/RegexValidations';

//Props and Emits
const emit = defineEmits(['return']);

//variables
const { proxy } = getCurrentInstance();
const Api = proxy?.Api;
const isPostValid = ref(false);
const isLoading = ref(false);
const phoneNumberMaska = reactive({});
const { defineField, handleSubmit, errors, meta } = useForm({
  initialValues: {
    name: '',
    email: '',
    phone: null,
  },
  validationSchema: yup.object({
    name: clientNameSchema,
    email: emailSchema,
    phone: TenDigitPhoneNumberSchema,
  }),
});

const [name, nameAttrs] = defineField('name');
const [email, emailAttrs] = defineField('email');
const [phone, phoneAttrs] = defineField('phone');

//methods
const postRegister = async (data) => {
  try {
    isLoading.value = true;
    await Api.post('/platform-customers', data, { Authorization: 'Bearer' });
    isPostValid.value = true;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};
const onSubmit = handleSubmit(async () => {
  await postRegister(data.value);
});

//computed
const data = computed(() => {
  const requiredData = {
    full_name: name.value,
    email: email.value,
  };

  return phone.value ? { ...requiredData, phone_number: phone.value } : requiredData;
});

const isFormValid = computed(() => meta.value.valid);

//watchers
watch(phone, (value) => {
  if (value) return;
  phone.value = null;
});
</script>

<style lang="sass" scoped>
.register-form
  .pre-submit
    display: flex
    flex-direction: column
    align-items: center
    .welcome-text
      font-size: $f-sm
      color: #000
      text-align: center
      margin-bottom: 12px
      h2
        color: $black
        font-weight: 700
        font-size: $fs-md
        margin-bottom: 1rem
      p:first-of-type
        font-weight: 600
    .field
      width: 100%
      margin-bottom: 0 !important
      &:last-of-type
        margin-bottom: 0 !important
      :deep(.help.is-danger)
        margin-top: -12px
    .sub-phone
      color: #000
      margin-top: 0
      font-size: $f-xs
      align-self: flex-start
    :deep(.register-button)
      width: 100%
      height: 37px
      font-weight: normal
      margin-top: 20px
    .return-login
      color: #000
      font-size:$f-xs
      margin-top: 8px
      .link-blue
        color: $color-blue-link
        font-size: 13px
        &:hover
          cursor: pointer
          text-decoration: underline
  .post-submit
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    .head
      font-size: $fs-md
      font-weight: 700
      color: $black
      margin-bottom: 1rem
      text-transform: uppercase
    .body, .sub-body
      font-size: $f-sm
      color: #000
      span
        font-weight: 700
    :deep(.register-button)
      width: 100%
      height: 37px
      font-weight: normal
      margin-top: 20px
</style>
