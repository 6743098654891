<template>
  <Modal
    id="ModalConcept"
    v-model:is-open="isModalActive"
    :loading="loading"
    size="is-large"
    :save-disabled="isDisabled"
    :header="headerTitle"
    button-label="Guardar cambios"
    @save="onSubmit"
  >
    <form ref="form">
      <div class="is-flex is-justify-content-space-between mb-4 mobile-adapt">
        <Field
          v-model="conceptData.short_name"
          text-transform="upper"
          class="w-35"
          maxlength="30"
          label="Etiqueta"
          placeholder="Ej. AA, D, INFORME FINANCIERO"
          required
        />
        <Field
          v-model="conceptData.long_name"
          text-transform="upper"
          class="w-65"
          maxlength="255"
          label="Nombre:"
          placeholder="Nombre del concepto"
          required
        />
      </div>
      <div class="is-flex is-justify-content-space-between is-align-items-center mb-5 mobile-adapt">
        <Field
          v-if="newTag.isAddTag"
          v-model="newTag.name"
          class="w-50-fit-color-square type-input mb-0 new-type"
          maxlength="60"
          label="Tipo"
          placeholder="Seleccionar tipo"
          required
        >
          <template #addonsRight>
            <Button variant="danger" icon-right="close" @click="() => (newTag.isAddTag = false)" />
          </template>
        </Field>
        <Autocomplete
          v-if="!newTag.isAddTag"
          :value="conceptData.concept_type_name"
          required
          class="w-50-fit-color-square type-input mb-0"
          label="Tipo"
          placeholder="Seleccionar tipo"
          field="name"
          :api="{
            url: '/concept-types',
            query: 'name',
            full: true,
          }"
          :variant="hasErrorType ? autocompleteErrorHandler.variant : ''"
          :message="hasErrorType ? autocompleteErrorHandler.message : ''"
          @error:not-selected="onError(autocompleteTypes.TYPE, !$event)"
          @error:not-found="onError(autocompleteTypes.TYPE, !$event)"
          @select-raw="(value) => onSelect(autocompleteTypes.TYPE, value)"
        >
          <template v-if="allowCreatetConceptTypeColor" #footer>
            <p @click="() => (newTag.isAddTag = true)">Añadir nuevo tipo</p>
          </template>
        </Autocomplete>
        <color-picker v-if="newTag.isAddTag" v-model:pureColor="newTag.color" disable-alpha />
        <color-picker
          v-else-if="allowEditConceptTypeColor"
          v-model:pureColor="conceptData.concept_type_color"
          disable-alpha
        />

        <Autocomplete
          :value="onEdit ? conceptData.short_name : ''"
          required
          class="w-50-fit-color-square mb-0"
          label="Orden"
          placeholder="Antes de "
          field="short_name"
          :sort-data="false"
          :data="autoCompleteData"
          :variant="hasErrorSequence ? autocompleteErrorHandler.variant : ''"
          :message="hasErrorSequence ? autocompleteErrorHandler.message : ''"
          @error:not-selected="onError(autocompleteTypes.SEQUENCE, !$event)"
          @select-raw="(value) => onSelect(autocompleteTypes.SEQUENCE, value)"
        />
        <DatePicker
          v-model="conceptData.start_date"
          required
          :max-date="conceptData.end_date"
          class="w-50 mb-0"
          label="Fecha de inicio"
        />
        <DatePicker
          v-model="conceptData.end_date"
          :min-date="conceptData.start_date"
          required
          class="w-50 mb-0"
          label="Fecha de término"
        />
      </div>
      <Field
        v-model="conceptData.description"
        label="Descripción del concepto"
        placeholder="Escribe la descripción para auditoría"
        type="textarea"
        maxlength="255"
        required
      />
      <p class="counter-field">{{ textLength }} / 255</p>
    </form>
  </Modal>
</template>
<script>
import { reactive, ref, toRefs, watch, getCurrentInstance, computed } from 'vue';
import { Modal, Field, DatePicker, Autocomplete, Button } from '@/components';
import moment from 'moment';
import useDialog from '@/utils/composables/useDialog';
import tinycolor from 'tinycolor2';
import { PermissionValidator } from '@/utils/Secure';
import { Permissions } from '@/utils/Secure';
export default {
  components: {
    Autocomplete,
    Button,
    DatePicker,
    Modal,
    Field,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    auditingId: { type: [String, Number], default: null },
    reload: { type: Function, default: null },
    allConcepts: { type: Array, default: () => [] },
    data: {
      type: Object,
      default: () => ({
        start_date: undefined,
        end_date: undefined,
        description: '',
        long_name: '',
        short_name: '',
        concept_type_id: null,
        concept_type_name: '',
        concept_type_color: 'D9D9D9',
      }),
    },
  },
  emits: ['update:active', 'update:is-open', 'edit-concept'],
  setup(props, { emit }) {
    const autocompleteTypes = {
      TYPE: Symbol('TYPE'),
      SEQUENCE: Symbol('SEQUENCE'),
    };
    const newTag = ref({
      isAddTag: false,
      name: '',
      color: 'D9D9D9',
      originalColor: props.data.concept_type_color,
    });
    const { proxy } = getCurrentInstance();
    const Api = proxy.Api;
    const form = ref(null);
    const { Notify } = useDialog();
    const loading = reactive({ save: false });
    const { isOpen, data } = toRefs(props);
    const isModalActive = ref(props.isOpen);
    const conceptData = ref(props.data);
    const autocompleteErrorHandler = reactive({
      targets: [],
      message: 'Seleccione una opción',
      variant: 'danger',
    });

    const resetForm = () => {
      conceptData.value.start_date = undefined;
      conceptData.value.end_date = undefined;
      conceptData.value.description = '';
      conceptData.value.long_name = '';
      conceptData.value.short_name = '';
      conceptData.value.concept_type_id = null;
      conceptData.value.concept_type_name = '';
      conceptData.value.concept_type_color = '';
    };
    const onError = (_target, isValid) => {
      const { targets } = autocompleteErrorHandler;
      if (isValid) autocompleteErrorHandler.targets = targets.filter((a) => a !== _target);
      else targets.push(_target);
    };
    const onSelect = (autoType, value) => {
      const { TYPE, SEQUENCE } = autocompleteTypes;
      switch (autoType) {
        case TYPE:
          if (value) {
            conceptData.value.concept_type_color = value.color;
            conceptData.value.concept_type_name = value.name;
            conceptData.value.concept_type_id = value.id;
            newTag.value.originalColor = value.color;
          } else {
            conceptData.value.concept_type_color = '';
            conceptData.value.concept_type_name = '';
            conceptData.value.concept_type_id = '';
          }
          break;
        case SEQUENCE:
          value
            ? (conceptData.value.sequence = value.sequence)
            : (conceptData.value.sequence = null);
          break;
      }
    };
    const validate = () => {
      let htmlValidator = false;
      if (form.value) {
        htmlValidator = form.value.checkValidity();
        if (!htmlValidator) form.value.reportValidity();
      }
      return htmlValidator;
    };
    const onSetConceptTypeColor = async () => {
      const { concept_type_color, concept_type_id } = conceptData.value;
      const { isAddTag, name, color, originalColor } = newTag.value;
      let concptTypeId = concept_type_id;
      if (isAddTag) {
        const { data } = await Api.post(`/concept-types`, {
          name,
          color: tinycolor(color).toHex().toUpperCase(),
          description: `Etiqueta ${name}`,
        });
        concptTypeId = data.id;
      } else if (originalColor != concept_type_color && concept_type_id) {
        const { data } = await Api.patch(`/concept-types/${concept_type_id}/color`, {
          color: tinycolor(concept_type_color).toHex().toUpperCase(),
        });
        concptTypeId = data.id;
      }

      return concptTypeId;
    };
    const onSubmit = async () => {
      const { start_date, end_date, description, long_name, short_name, id, sequence } =
        conceptData.value;
      const startDate = moment(start_date),
        endDate = moment(end_date);
      if (!validate() || !startDate.isValid() || !endDate.isValid()) return;
      loading.save = true;
      try {
        let concept_type_id = await onSetConceptTypeColor();
        await Api[`${onEdit.value ? 'put' : 'post'}`](
          `/auditing/${props.auditingId}/concept${onEdit.value ? '/' + id : ''}`,
          {
            description,
            long_name,
            short_name,
            concept_type_id,
            sequence,
            start_date: startDate.format('YYYY-MM-DD hh:mm:ss'),
            end_date: endDate.format('YYYY-MM-DD hh:mm:ss'),
          },
        );
        await props.reload(props.auditingId);
        if (onEdit.value)
          Notify(
            'success',
            'Concepto editado',
            `El concepto ${conceptData.value.long_name} fue editado de manera exitosa`,
          );
        else
          Notify(
            'success',
            'Concepto creado',
            `El concepto ${conceptData.value.long_name} fue creado de manera exitosa`,
          );
        isModalActive.value = false;
      } catch (error) {
        console.log(error);
      }
      loading.save = false;
    };
    const isDisabled = computed(() => {
      const {
        long_name,
        short_name,
        description,
        start_date,
        end_date,
        concept_type_name,
        sequence,
      } = conceptData.value;
      const textFields = long_name.length && short_name.length && description.length;
      const autoCompleteData = concept_type_name && sequence;
      const datePickers = start_date && end_date;
      return Boolean(!textFields || !datePickers || !autoCompleteData);
    });
    const autoCompleteData = computed(() => {
      const conceptsData = JSON.parse(JSON.stringify(props.allConcepts));
      const lastItem = conceptsData[conceptsData.length - 1]?.sequence;
      return [
        { short_name: 'Al inicio de todos', sequence: 1 },
        {
          short_name: 'Al final de todos',
          sequence: lastItem ? lastItem + 1 : 1,
        },
        ...conceptsData.map(({ short_name, sequence }) => {
          return { short_name: `Antes de #${sequence}: ${short_name}`, sequence: sequence };
        }),
      ];
    });
    const onEdit = computed(() => conceptData.value.id);
    const headerTitle = computed(() => (onEdit.value ? 'Editar concepto' : 'Crear concepto'));
    const textLength = computed(() => conceptData.value?.description.length ?? 0);
    const hasErrorType = computed(
      () =>
        autocompleteErrorHandler.targets.filter((target) => target == autocompleteTypes.TYPE)
          .length,
    );
    const hasErrorSequence = computed(
      () =>
        autocompleteErrorHandler.targets.filter((target) => target == autocompleteTypes.SEQUENCE)
          .length,
    );
    const allowEditConceptTypeColor = computed(() => {
      const { Patch } = Permissions.conceptTypes;
      return PermissionValidator(Patch);
    });
    const allowCreatetConceptTypeColor = computed(() => {
      const { Create } = Permissions.conceptTypes;
      return PermissionValidator(Create);
    });
    watch(
      () => newTag.value.isAddTag,
      (value) => {
        if (!value) {
          newTag.value.name = '';
          newTag.value.color = 'D9D9D9';
        }
      },
    );
    watch(data, (value) => (conceptData.value = value));
    watch(isOpen, (value) => (isModalActive.value = value));
    watch(isModalActive, (value) => {
      emit('update:active', value);
      emit('update:is-open', value);
      if (!value) resetForm();
    });

    return {
      autocompleteErrorHandler,
      autocompleteTypes,
      conceptData,
      isModalActive,
      newTag,
      form,
      loading,

      // computadas
      allowCreatetConceptTypeColor,
      allowEditConceptTypeColor,
      autoCompleteData,
      hasErrorSequence,
      hasErrorType,
      headerTitle,
      onEdit,
      textLength,

      // Metodos
      onSubmit,
      isDisabled,
      onError,
      onSelect,
    };
  },
};
</script>
<style lang="sass" scoped>
#ModalConcept
  :deep(.dropdown-menu.dropdown-content.is-opened-bottom)
    width: auto
  :deep(.animation-content)
    overflow: unset
    width: 868px !important
    @media screen and (max-width: $bp-md)
      width: 100% !important
  :deep(.animation-content) .card-content
    overflow: unset
    .mobile-adapt
      flex-wrap: wrap
      justify-content: flex-start !important
      gap: 4px
    @media screen and (max-width: $bp-md)
      max-height: 40vh
      overflow: scroll
      .type-input
        width: calc( 100% - 40px ) !important
      .mobile-adapt
        > *
          width: 100%
        .vc-color-wrap
          width: 36px
  :deep(.vc-color-wrap)
    width: 36px
    height: 36px
    margin: 0
    border-radius: 3px
    margin-top: 28px
    &:hover .current-color
      background: $color-blue-light !important
  .date-picker :deep(.datepicker)
    width: 100%
    > .dropdown > .dropdown-trigger .input
      padding-left: 32px
  .w-50-fit-color-square
    width: calc(50% - 24px)
  .w-50
    width: calc(50% - 4px)
  .w-65
    width: calc( 65% - 5px )
  .w-35
    width: calc( 35% - 5px )
    min-width: 228px
  .w-28
    width: calc( 28% - 16px )
  .w-31
    width: calc( 25% - 8px )
  .w-20
    width: calc( 20% - 8px )
  .new-type :deep(.field).has-addons
    .input
      height: 100%
    .button
      height: 35px
      padding: 0 4px
      .icon
        margin: 0
</style>
