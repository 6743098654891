export const vapidKey =
  'BDKav8xE0Hx7UUlMMIGnBaD_nNXq3lwJ79NCtckPm1kIm9nTkHepeYasuPg0QfmWQ_AgaIgYLli9oPC60EMhv-0';
export const config = {
  apiKey: 'AIzaSyB4BHRHhI-CBOA7romiY8eGaqncw9i57-A',
  authDomain: 'despacho-qa.firebaseapp.com',
  projectId: 'despacho-qa',
  storageBucket: 'despacho-qa.appspot.com',
  messagingSenderId: '408725387712',
  appId: '1:408725387712:web:76ac4e873d744f94b67025',
  measurementId: 'G-F86R54EETV',
};